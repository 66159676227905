<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    append-to-body
    :modal="true"
    style="text-align:left;"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="1000px"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
      -->
      <el-form
        ref="queryForm"
        :model="queryModel"
        inline
        class="demo-form-inline"
        label-width="100px"
      >
        <el-form-item label="设备编号" prop="deviceNo">
          <el-input type="text" size="mini" v-model="queryModel.deviceNo" ></el-input>
        </el-form-item>
        <el-form-item label="设备名称" prop="deviceName">
          <el-input type="text" size="mini" v-model="queryModel.deviceName" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="mini"
            icon="ios-search"
            @click="changePage(1)"
            :loading="loading"
          >查询</el-button>&nbsp;
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <el-row class="button-group">
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          :disabled="multipleSelection.length==0"
          @click="handleBatchBound"
        >批量绑定</el-button>
      </el-row>
      <el-table
        :data="tableData"
        style="min-height:400px;"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" :selectable="disableSelect" width="55"></el-table-column>
        <el-table-column prop="deviceNo" label="设备编号" width="220"></el-table-column>
        <el-table-column prop="aliasName" label="设备名称" width="180"></el-table-column>
        <el-table-column prop="isBind" label="绑定状态" width="120">
          <template slot-scope="{row}">{{row.isBind == 0? "未绑定" : "已绑定"}}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <span v-if="row.isBind == 0">
              <el-button size="mini" type="success" @click="handleBound(row)">绑定设备</el-button>
            </span>
            <span v-else>
              <el-button size="mini" type="danger" @click="handleUnBound(row)">解除绑定</el-button>
              &nbsp;
              <el-button size="mini" type="primary" @click="handleSyncDevice(row,'all')">同步（所有人）</el-button>
              &nbsp;
              <el-button size="mini" type="primary" @click="handleSyncDevice(row,'bind')">同步（已绑定）</el-button>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
      <personDeviceRelation-detail
        v-if="showModal"
        :businessKey="businessKey"
        :title="modalTitle"
        @close="onDetailModalClose"
      ></personDeviceRelation-detail>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import PersonDeviceRelationDetail from "./personDeviceRelation-detail";
import personDeviceRelationApi from "@/api/base/personDeviceRelation";
import companyInfoApi from "@/api/base/companyInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["title","companyId"],
  data() {
    var self = this;

    return {
      formModel: {},
      queryModel: {
        deviceNo: "",
        deviceName: "",
        personId: ""
      },
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      showDialog: true,
      loading: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    disableSelect(row, rowIndex) {
      //多选按钮是否禁用
      if (row.isBindPerson) {
        return false;
      } else {
        return true;
      }
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("companyId", self.companyId);

      formData.append("deviceNo", self.queryModel.deviceNo);
      formData.append("deviceName", self.queryModel.deviceName);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      companyInfoApi
        .queryBindDeviceList(formData)
        .then(function(response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch(error => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleBatchBound() {
      //批量绑定
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否绑定?", "提示", "warning").then(result => {
        if (result) {
          self.loading = true;
          var formData = new FormData();
          formData.append("companyId", self.companyId);
          formData.append("deviceIds", idList);
          return companyInfoApi.bindDevice(formData).then(function(response) {
            self.loading = false;

            var jsonData = response.data;
            self.changePage(self.pageIndex);
            if (jsonData.result) {
              self.$message({
                message: "绑定成功!",
                type: "success"
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });
            }
          });
        }
      });
    },
    handleBound(record,mode) {
      //绑定设备
      var self = this;

      this.$confirm("是否绑定?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true
      }).then(result => {
        if (result) {
          self.loading = true;
          var formData = new FormData();
          
          formData.append("companyId", self.companyId);
          formData.append("deviceIds", record.id);
          formData.append("mode", mode);

          return companyInfoApi.bindDevice(formData).then(function(response) {
            self.loading = false;

            var jsonData = response.data;
            self.changePage(self.pageIndex);
            if (jsonData.result) {
              self.$message({
                message: "绑定成功!",
                type: "success"
              });
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });
            }
          });
        }
      });
    },
    handleUnBound(record) {
      //取消绑定
      var self = this;

      this.$confirm("是否解除绑定？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true
      }).then(() => {
        self.loading = true;
        var formData = new FormData();
        formData.append("companyId", self.companyId);
        formData.append("deviceIds", record.id);

        return companyInfoApi.unbindDevice(formData).then(function(response) {
          self.loading = false;
          var jsonData = response.data;
          self.changePage(self.pageIndex);
          if (jsonData.result) {
            self.$message({
              message: "解绑成功!",
              type: "success"
            });
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
          }
        });
      });
    },
    handleSyncDevice(record,mode){
      //同步照片
      var self = this;

      this.$confirm("是否将单位下人员照片同步到设备？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        distinguishCancelAndClose: true
      }).then(() => {
        self.loading = true;
        var formData = new FormData();
        formData.append("companyId", self.companyId);
        formData.append("deviceId", record.id);
        formData.append("mode", mode);
        
        return companyInfoApi.syncDevice(formData).then(function(response) {
          self.loading = false;
          var jsonData = response.data;

          if (jsonData.result) {
            self.$message({
              message: "已下发同步任务" + jsonData.data  + "个，请查看同步队列!",
              type: "success"
            });
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    }
  },
  mounted: function() {
    this.changePage(1);
  },
  components: {
    "personDeviceRelation-detail": PersonDeviceRelationDetail
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>