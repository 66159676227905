import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/base/iconInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/base/iconInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/base/iconInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/base/iconInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/base/iconInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/base/iconInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/base/iconInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function listByRoleId(formData){
  return request.post(constant.serverUrl + "/base/iconInfo/listByRoleId", formData);
}

function getIconTypeList(){
  return request.post(constant.serverUrl + "/base/iconInfo/getIconTypeList");
}

function findAll() {
  return request.post(constant.serverUrl + "/base/iconInfo/list");
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,listByRoleId,getIconTypeList,findAll
}