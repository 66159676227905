import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/sys/dataDictionary/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/sys/dataDictionary/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function query(formData) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/query", formData);
}

function queryChildren(formData) {
  return request.post(constant.serverUrl + "/sys/dataDictionary/queryChildren", formData);
}

function findByCatalogName(obj){
  var formData = new FormData();
  var str = Object.prototype.toString.call(obj);

  if(str.indexOf("FormData")==-1){
    for(var key in obj){
      formData.append(key, obj[key]);
    }
  }
  else{
    formData = obj;
  }

  return request.post(constant.serverUrl + "/sys/dataDictionary/findByCatalogName", formData);
}

export default {
  pageList, create, edit, add, update, remove, batchRemove,query,queryChildren,findByCatalogName
}