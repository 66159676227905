import request from '@/utils/request'
import constant from '@/constant'

function findRelatedIcon(companyId){
    return request.get(constant.serverUrl + `/base/companyIcon/findRelatedIcon?companyId=${companyId}`);
}

function saveRelatedIcon(companyId,idList){
    var formData = new FormData();
  
    formData.append("companyId",companyId);
    formData.append("idList",idList.join(","));
  
    return request.post(constant.serverUrl + "/base/companyIcon/saveRelatedIcon",formData);
}

export default {
    findRelatedIcon, saveRelatedIcon
}